import React, { useState } from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby';

import Person from './Person'

import useWindowSize from '../hooks/useWindowSize'

const HeadingContainer = styled.div`
  position: relative;
`

const Heading = styled.h1`
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.76px;
  color: #efe0d7;
  margin-left: 18px;
  @media (min-width: 680px) {
    margin-left: 28px;
    font-size: 37px;
    line-height: 40px;
    letter-spacing: 1.28px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2.08px;
  }
`

const VerticalLine = styled.div`
  width: 3px;
  height: 158px;
  background-color: #efe0d7;
  position: absolute;
  left: 0px;
  top: 15px;
  @media (min-width: 680px) {
    width: 4px;
    height: 238px;
    top: 18px;
  }
  @media (min-width: 1200px) {
    height: 375px;
    top: 15px;
  }
`

const TutorsContainer = styled.div`
  @media (min-width: 680px) {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
  }
`

const Tutor = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  @media (min-width: 680px) {
    width: calc((100% - 34px) / 2);
  }
  @media (min-width: 1200px) {
    width: calc((100% - 140px) / 3);
    margin-bottom: 0px;
  }
`

const LoadMoreContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    display: none;
  }
`

const LoadMore = styled.button`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #0859ff;
  padding-bottom: 6px;
  border-bottom: 1px solid #0859ff;
`
const tutors = [
  {
      "imageKey": "amy",
      "name": "Amy T",
      "level": null,
      "subject": "Classics",
      "university": "University of Oxford",
      "biography": [
          {
              "type": "paragraph",
              "text": "Amy completed her undergraduate degree in Classics at the University of Oxford; she is now reading for a masters in PPE. ",
              "spans": []
          },
          {
              "type": "paragraph",
              "text": "Amy is enthusiastic about the ancient world, and she shares this enthusiasm with students of all ages to help them fulfil their potential at 13+ admissions, GCSE, and A-level. ",
              "spans": []
          }
      ]
  },
  {
      "imageKey": "rowan",
      "name": "Rowan W",
      "level": null,
      "subject": "Mathematics",
      "university": "University of Cambridge",
      "biography": [
          {
              "type": "paragraph",
              "text": "Rowan is a masters student in Mathematics at the University of Cambridge. ",
              "spans": []
          },
          {
              "type": "paragraph",
              "text": "Rowan has tutored students from age 12 to 18 — from those struggling with their first official maths exam, to those seeking admission to the best universities. Rowan prides himself on helping students understand the theory behind a problem, rather than just memorising methods that they can’t explain.",
              "spans": []
          }
      ]
  },
  {
      "imageKey": "guillermo",
      "name": "Guillermo V",
      "level": null,
      "subject": "Economics",
      "university": "University of Cambridge",
      "biography": [
          {
              "type": "paragraph",
              "text": "Guillermo completed his undergraduate degree in economics at the University of Cambridge and is currently reading for a masters degree in finance at the University of Madrid. ",
              "spans": []
          },
          {
              "type": "paragraph",
              "text": "Guillermo has been tutoring since secondary school, online and offline, focussing on economics and mathematics-related curricula. He has a passion for teaching that helps guides his students to their desired results.  ",
              "spans": []
          }
      ]
  },
  {
      "imageKey": "william",
      "name": "William W",
      "level": null,
      "subject": "Computer Science",
      "university": "University of Chicago",
      "biography": [
          {
              "type": "paragraph",
              "text": "William is a UChicago MPCS graduate. ",
              "spans": []
          },
          {
              "type": "paragraph",
              "text": "William has helped various students aged 10 to 20 science subjects, focussing on the AP, SAT 1 and the SAT 2 subject tests. William likes to inspire students to find the link between knowledge and questions so that they can use past knowledge to solve problems they’ve never encountered before instead of just memorising ways to solve them.",
              "spans": []
          }
      ]
  },
  {
      "imageKey": "seb",
      "name": "Sebastian D",
      "level": null,
      "subject": "French and Linguistics",
      "university": "University of Oxford",
      "biography": [
          {
              "type": "paragraph",
              "text": "Sebastian is a fourth year undergraduate and choral scholar at the University of Oxford studying French and Linguistics ",
              "spans": []
          },
          {
              "type": "paragraph",
              "text": "Sebastian is experienced at teaching French and linguistics-related subjects at multiple levels including GCSE and A-Level, as well as language aptitude tests such as the Oxford MLAT.",
              "spans": []
          }
      ]
  },
  {
      imageKey: 'daniel',
      "name": "Daniel E",
      "level": null,
      "subject": "English Language and Literature",
      "university": "University of Cambridge",
      "biography": [
          {
              "type": "paragraph",
              "text": "Daniel is a graduate of the University of Cambridge with a degree in English Language and Literature.",
              "spans": []
          },
          {
              "type": "paragraph",
              "text": "Daniel was a volunteer classroom assistant at a local primary school for three years. His passion for drama and directing led him to take up a career in theatre. Recently, he has begun to focus on online education to fit around his busy schedule, recently helping a number of students with their GCSE and A-Level exams.",
              "spans": []
          }
      ]
  }
];

export const query = graphql`
  query tutor {
    william: file(relativePath: { eq: "william.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    harry: file(relativePath: { eq: "harry.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    seb: file(relativePath: { eq: "seb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    amy: file(relativePath: { eq: "amy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    daniel: file(relativePath: { eq: "daniel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    guillermo: file(relativePath: { eq: "guillermo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rowan: file(relativePath: { eq: "rowan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const Tutors = () => {
  const { width } = useWindowSize()
  const tutorsCount = 6
  const [tutorsToShow, setTutorsToShow] = useState(2)
  const incrementTutorsToShow = () => {
    setTutorsToShow(t => t + 2)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <>
            <HeadingContainer id="tutors">
              <Heading>{`our tutors`}</Heading>
              <VerticalLine />
            </HeadingContainer>
            <TutorsContainer>
              {tutors.map(({ name, level, university, subject, imageKey, biography }, index) => {
                if (index < tutorsToShow || width >= 1000) {
                  return (
                    <Tutor key={index}>
                      <Person
                        smallOnTablet
                        name={name}
                        level={level}
                        university={university}
                        subject={subject}
                        portrait={data[imageKey]}
                        biography={biography}
                      />
                    </Tutor>
                  )
                }
              })}
            </TutorsContainer>
            {tutorsToShow < tutorsCount && (
              <LoadMoreContainer>
                <LoadMore onClick={incrementTutorsToShow}>load more</LoadMore>
              </LoadMoreContainer>
            )}
          </>
        )}
      }
    />
  )
}

export default Tutors

