import React from 'react'
import styled from 'styled-components'

import Carousel from './Carousel/Carousel'

const HeadingContainer = styled.div`
  position: relative;
  margin-top: 60px;
  margin-bottom: 100px;
  @media (min-width: 1200px) {
    margin-top: 200px;
    margin-bottom: 190px;
  }
`

const Heading = styled.h1`
  max-width: 130px;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.76px;
  color: #efe0d7;
  margin-left: 18px;
  @media (min-width: 680px) {
    max-width: 240px;
    margin-left: 28px;
    font-size: 37px;
    line-height: 40px;
    letter-spacing: 1.28px;
  }
  @media (min-width: 1200px) {
    max-width: 384px;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2.08px;
  }
  @media (min-width: 1300px) {
    margin-left: 7px;
  }
  @media (min-width: 1440px) {
    margin-left: 28px;
  }
`

const VerticalLine = styled.div`
  width: 3px;
  height: 169px;
  background-color: #efe0d7;
  position: absolute;
  left: -1px;
  top: 2px;
  @media (min-width: 680px) {
    top: 6px;
    width: 4px;
    height: 238px;
  }
  @media (min-width: 1200px) {
    left: -4px;
    height: 375px;
  }
  @media (min-width: 1300px) {
    left: -31px;
  }
  @media (min-width: 1440px) {
    left: -11px;
  }
  @media (min-width: 1460px) {
    left: -1px;
  }
`

const BenefitsContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  .embla__slide__inner {
    height: 100%;
    min-height: 200px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 680px) {
      min-height: 220px;
      max-height: 220px;
    }
  }
  @media (min-width: 1200px) {
    max-width: 767px;
    margin-left: auto;
    margin-right: auto;
    .embla__slide__inner {
      min-height: 310px;
      max-height: 310px;
    }
    .embla__outer {
      padding-bottom: 100px;
    }
    .embla__dots {
      bottom: -100px;
      left: 0;
      right: 0;
      transform: translateX(0px);
    }
  }
`

const Benefit = styled.blockquote`
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  @media (min-width: 680px) {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.75px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
    line-height: 76px;
    letter-spacing: 1.25px;
  }
`

const Benefits = () => {
  const benefits_heading = 'benefits of being a tutor';
  const benefits = [
      {
          "benefit": "Flexible: tutors can manage their time availability and commitment level"
      },
      {
          "benefit": "Rewarding: tutors are paid a fair and competitive rate for their sessions"
      },
      {
          "benefit": "Rigorous: students are goal-oriented, giving our tutors a chance to shine"
      },
      {
          "benefit": "Convenient: an easy to use platform designed to maximise the effectiveness of online tutoring"
      }
  ];
  return (
    <>
      <HeadingContainer>
        <Heading>{benefits_heading}</Heading>
        <VerticalLine />
      </HeadingContainer>
      <BenefitsContainer>
        {benefits.length > 0 && (
          <Carousel autoplay delayLength={5000} showDots>
            {benefits.map(({ benefit }, index) => {
              return <Benefit key={index}>{benefit}</Benefit>
            })}
          </Carousel>
        )}
      </BenefitsContainer>
    </>
  )
}

export default Benefits
