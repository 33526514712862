import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FormContext } from '../context/form-context'

import Tutors from '../components/Tutors'
import Levels from '../components/Levels'
import SEO from '../components/SEO'

import Container from '../styles/Container'

import arrowUp from '../images/arrow-up.svg'
import mouseCursorUp from '../images/mouse-cursor-up.png'
import Benefits from '../components/Benefits'

import grid25x25 from '../images/grid-25x25.svg'
import grid50x50 from '../images/grid-50x50.svg'
import grid25x25Md from '../images/grid-25x25-Md.svg'
import grid50x50Md from '../images/grid-50x50-Md.svg'

import grid76x76 from '../images/grid-76x76.svg'
import grid38x38 from '../images/grid-38x38.svg'

import grid1 from '../images/grid-teach-with-us-1.svg'
import grid2 from '../images/grid-teach-with-us-2.svg'
import grid3 from '../images/grid-teach-with-us-3.svg'

const Heading = styled.h1`
  max-width: 240px;
  margin-bottom: 30px;
  margin-top: 86px;
  margin-left: 20px;
  font-family: Dignitas, sans-serif;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  color: #eadaaa;
  @media (min-width: 680px) {
    max-width: 417px;
    margin-left: 0px;
    font-size: 45px;
    line-height: 49px;
    letter-spacing: 1.56px;
  }
  @media (min-width: 980px) {
    max-width: 660px;
    font-size: 52px;
    line-height: 58px;
    letter-spacing: 1.8px;
  }
  @media (min-width: 1200px) {
    max-width: 775px;
    margin-bottom: 120px;
    margin-top: 150px;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2.08px;
  }
`

const Yellow = styled.span`
  color: #f1ab00;
`

const Break1 = styled.br`
  @media (min-width: 980px) {
    display: none;
  }
`

const Break2 = styled.br`
  @media (min-width: 680px) {
    display: none;
  }
`

const Break3 = styled.br`
  display: none;
  @media (min-width: 680px) {
    display: block;
  }
  @media (min-width: 980px) {
    display: none;
  }
`

const NoWrap = styled.span`
  @media (min-width: 680px) {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const NextPageContainer = styled.div`
  position: relative;
  margin-top: 200px;
  margin-bottom: 90px;
  text-align: center;
  @media (min-width: 1200px) {
    margin-top: 299px;
    margin-bottom: 68px;
  }
`

const NextPage = styled(Link)`
  position: relative;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.86px;
  color: #0859ff;
  @media (min-width: 1200px) {
    font-size: 110px;
    line-height: 92px;
    letter-spacing: 2.36px;
    padding-top: 59px;
    padding-right: 52px;
    padding-left: 52px;
    padding-bottom: 59px;
    left: -34px;
    background-color: #fdfbf9;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 5px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 108px;
      }
    }
  }
`

const MouseCursor = styled.img`
  width: 123px;
  position: absolute;
  top: -180px;
  right: 10px;
  @media (min-width: 1200px) {
    width: 200px;
    position: absolute;
    top: -254px;
    right: 22px;
  }
`

const ReturnToTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 71px;
  padding-top: 24px;
  @media (min-width: 1200px) {
    padding-bottom: 77px;
    padding-top: 0px;
  }
`

const ReturnToTopButton = styled.button`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.11px;
  color: #0859ff;
  position: relative;
  @media (min-width: 1200px) {
    transform: translateX(232px);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.13px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 27px;
    background-color: #fdfbf9;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 1px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 30px;
      }
    }
  }
`

const ReturnToTopImg = styled.img`
  display: block;
  height: 33px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const GridContainer = styled.div`
  position: relative;
`

const GridSmall = styled.div`
  background-image: url(${grid25x25});
  position: absolute;
  height: calc(100% - 155px);
  width: 100%;
  margin-top: 155px;
  z-index: -1;
  @media (min-width: 680px) {
    background-image: url(${grid25x25Md});
    height: calc(100% - 186px);
    margin-top: 186px;
  }
  @media (min-width: 1200px) {
    background-image: url(${grid38x38});
    height: calc(100% - 352px);
    margin-top: 352px;
    background-position: center top;
  }
  @media (min-width: 1440px) {
  }
`

const GridSmallTop = styled.div`
  margin-top: 155px;
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  z-index: -1;
  background-color: #fdfbf9;

  @media (min-width: 680px) {
    margin-top: 186px;
  }
  @media (min-width: 1200px) {
    margin-top: 352px;
  }
`

const GridBig = styled.div`
  background-image: url(${grid50x50});
  position: absolute;
  height: calc(100% - 625px);
  width: 100%;
  margin-top: 625px;
  z-index: -1;
  @media (min-width: 680px) {
    background-image: url(${grid50x50Md});
    height: calc(100% - 735px);
    margin-top: 735px;
  }
  @media (min-width: 1200px) {
    background-image: url(${grid76x76});
    height: calc(100% - 1188px);
    margin-top: 1188px;
    background-position: center top;
  }
  @media (min-width: 1440px) {
  }
`
const Grid1 = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 153px;
    background-image: url(${grid1});
    background-position: center top;
    z-index: -1;
  }
`

const Grid2 = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: 152px;
    width: 100%;
    height: 153px;
    background-image: url(${grid2});
    background-position: center top;
    z-index: -1;
  }
`

const Grid3 = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: 456px;
    width: 100%;
    height: 153px;
    background-image: url(${grid3});
    background-position: center top;
    z-index: -1;
  }
`
// Workaround for Mac Chrome scrollbar issue
const CustomContainer = styled(Container)`
  @media (min-width: 1200px) {
    max-width: 1180px;
  }
  @media (min-width: 1300px) {
    max-width: 1280px;
  }
  @media (min-width: 1440px) {
    max-width: 1420px;
  }
  @media (min-width: 1460px) {
    max-width: 1440px;
  }
`

const TeachWithUs = () => {
  const { setShowForm } = React.useContext(FormContext);
  const goToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
  }
  return (
    <>
      <Container>
        <SEO title={`Teach With Us`} description={``} images={``} />
        <Heading>
          Our tutors are some of the most qualified, independent and pro-active around – each with a diverse range of
          skills and experience. But all Oros tutors have one thing in common:
          <Break1 />{' '}
          <Yellow>
            the dedication to meaningful,
            <Break2 />{' '}
            <NoWrap>
              well-
              <Break3 />
              paid
            </NoWrap>{' '}
            work when they want. Meet some of them below...
          </Yellow>
        </Heading>
        <Tutors />
      </Container>
      <GridContainer>
        <GridSmall />
        <GridSmallTop />
        <GridBig />
        <Grid1 />
        <Grid2 />
        <Grid3 />
        <CustomContainer>
          <Benefits />
          <Levels
            setShowForm={setShowForm}
          />
          <NextPageContainer>
            <NextPage to="/how-it-works">how it works</NextPage>
            <MouseCursor src={mouseCursorUp} alt="" />
          </NextPageContainer>
          <ReturnToTopContainer>
            <ReturnToTopButton onClick={goToTop}>
              <ReturnToTopImg src={arrowUp} alt="Return to Top" />
              <div>return to top</div>
            </ReturnToTopButton>
          </ReturnToTopContainer>
        </CustomContainer>
      </GridContainer>
    </>
  )
}

export default TeachWithUs;