import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'

const HeadingContainer = styled.div`
  margin-top: 90px;
  position: relative;
`

const Heading = styled.h1`
  max-width: 130px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #002c5e;
  margin-left: 18px;
  @media (min-width: 680px) {
    max-width: 180px;
    margin-left: 28px;
    font-size: 22px;
    line-height: 28px;
  }
  @media (min-width: 1200px) {
    max-width: 243px;
    font-size: 30px;
    line-height: 38px;
  }
  @media (min-width: 1300px) {
    margin-left: 7px;
  }
  @media (min-width: 1440px) {
    margin-left: 28px;
  }
`

const VerticalLine = styled.div`
  width: 3px;
  height: 174px;
  background-color: #002c5e;
  position: absolute;
  left: -1px;
  top: 0px;
  @media (min-width: 680px) {
    height: 196px;
  }
  @media (min-width: 1200px) {
    top: 3px;
    left: -4px;
    width: 4px;
    height: 304px;
  }
  @media (min-width: 1300px) {
    left: -31px;
  }
  @media (min-width: 1440px) {
    left: -11px;
  }
  @media (min-width: 1460px) {
    left: -1px;
  }
`

const ParagraphContainer = styled.div`
  max-width: 299px;
  height: 349px;
  margin-top: 84px;
  margin-bottom: 50px;
  padding: 46px 40px 75px 30px;
  background-color: #fdfbf9;
  @media (min-width: 500px) {
    max-width: 349px;
    height: 299px;
  }
  @media (min-width: 680px) {
    max-width: 349px;
    padding: 32px 32px 46px 32px;
    margin-top: 40px;
    margin-left: 100px;
    margin-bottom: 50px;
  }
  @media (min-width: 1200px) {
    max-width: 454px;
    height: 303px;
    padding: 32px 10px 46px 30px;
    margin-top: 155px;
    margin-left: 110px;
    margin-bottom: 0px;
  }
  @media (min-width: 1300px) {
    margin-left: 84px;
  }
  @media (min-width: 1440px) {
    margin-left: 104px;
  }
  @media (min-width: 1460px) {
    margin-left: 114px;
  }
`

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0px;
  }
  @media (min-width: 680px) {
    letter-spacing: 0.25px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`

const InfoBox = styled.div`
  max-width: 199px;
  height: 249px;
  margin-top: 51px;
  padding: 35px 20px 34px 22px;
  background-color: #fdfbf9;
  @media (min-width: 500px) {
    max-width: 249px;
  }
  @media (min-width: 680px) {
    max-width: 349px;
    height: 149px;
    padding: 21px 65px 27px 32px;
  }
  @media (min-width: 1200px) {
    max-width: 454px;
    margin-top: 77px;
    margin-left: 34px;
  }
  button {
    color: #0859ff;
    font-weight: 700;
    line-height: inherit;
    position: relative;
    font-weight: 700;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 2px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 40px;
      }
    }
  }
  @media (min-width: 1300px) {
    margin-left: 8px;
  }
  @media (min-width: 1440px) {
    margin-left: 28px;
  }
  @media (min-width: 1460px) {
    margin-left: 38px;
  }
`

const Table = styled.div``

const Row = styled.div`
  max-width: 301px;
  display: flex;
  position: relative;
  height: 100px;
  border: 1px solid #002c5e;
  margin-left: -1px;
  :not(:last-child) {
    border-bottom: none;
  }
  @media (min-width: 500px) {
    max-width: 351px;
  }
  @media (min-width: 680px) {
    max-width: 451px;
  }
  @media (min-width: 1200px) {
    height: 152px;
    max-width: 685px;
    margin-left: 109px;
  }
  @media (min-width: 1300px) {
    max-width: 761px;
    margin-left: 83px;
  }
  @media (min-width: 1440px) {
    max-width: 761px;
    margin-left: 103px;
  }
  @media (min-width: 1460px) {
    margin-left: 113px;
  }
`

const Left = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 680px) {
    flex-direction: row;
    padding-left: 27px;
    padding-right: 14px;
    justify-content: flex-start;
    align-items: center;
  }
  @media (min-width: 1200px) {
    padding-left: 73px;
    padding-right: 20px;
  }
`

const First = styled.div`
  font-weight: 700;
  @media (min-width: 680px) {
    min-width: 90px;
    font-size: 13px;
    line-height: 16px;
  }
  @media (min-width: 1200px) {
    width: 150px;
    font-size: 20px;
    line-height: 25px;
  }
`

const Second = styled.div`
  @media (min-width: 680px) {
    margin-left: 4px;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.36px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
    margin-left: 10px;
  }
`

const Dot = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 100px;
  top: 42px;
  right: 91px;
  background-color: #002c5e;
  ${p => p.color === 'cream' && 'background-color: #EFE0D7;'}
  ${p => p.color === 'gold' && 'background-color: #D5B455;'}
  ${p => p.color === 'light-blue' && 'background-color: #0859FF;'}
  @media (min-width: 680px) {
    width: 21px;
    height: 21px;
    top: 38px;
    right: 139px;

  }
  @media (min-width: 1200px) {
  width: 35px;
  height: 35px;
  top: 58px;
  right: 209px;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  padding-left: 18px;
  border-left: 1px solid #002c5e;
  font-size: 12px;
  line-height: 20px;
  @media (min-width: 680px) {
    min-width: 150px;
    padding-left: 31px;
    font-size: 13px;
    line-height: 30px;
    letter-spacing: 0.31px;
    font-weight: 700;
  }
  @media (min-width: 1200px) {
    min-width: 228px;
    padding-left: 42px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.48px;
  }
`

const Price = styled.div``

const PerHour = styled.div`
  display: none;
  @media (min-width: 680px) {
    display: block;
    margin-left: 5px;
  }
`

const levels = [
  { title: 'Band One', level: 'Undergraduate', price:'£17 - £22', rate: 'p/h', color:'dark-blue' },
  { title: 'Band Two', level: 'MA', price:'£22 - £28', rate: 'p/h', color:'cream' },
  { title: 'Band Three', level: 'MA Graduate', price:'£28 - £34', rate: 'p/h', color:'gold' },
  { title: 'Band Four', level: 'PHD and Professional teachers', price:'£34 - £40', rate: 'p/h', color:'light-blue' },
]

const Levels = ({ setShowForm }) => {
  return (
    <>
      <HeadingContainer>
        <Heading>{`Tutoring Levels and Price Bands`}</Heading>
        <VerticalLine />
      </HeadingContainer>
      
      <ParagraphContainer>
          <Paragraph>{`We recruit tutors from top universities with experience offering tuition in A-Level, GCSE, IB, AP, SAT 1, SAT 2 and other subjects including university admissions prep.`}</Paragraph>
          <Paragraph>{`Oros has four price bands, each associated with a certain level of experience or academic achievement. Wage brackets are visible after application!`}</Paragraph>
      </ParagraphContainer>
  
      <Table>
        {levels &&
          levels.map(({ title, level, price, rate = 'p/h', color }, index) => {
            return (
              <Row key={index}>
                <Left>
                  {title && <First>{title}</First>}
                  {level && <Second>{level}</Second>}
                </Left>
                {/*<Dot color={color} />*/}
                {/*<Right>*/}
                {/*  {price && (*/}
                {/*    <>*/}
                {/*      <Price>{price}</Price>*/}
                {/*      <PerHour>{rate}</PerHour>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</Right>*/}
              </Row>
            )
          })}
      </Table>
      <InfoBox>
        <Paragraph>
          Our tutors are all vetted via a CV, short cover letter and interview. For more information on how to apply
          please see our <button onClick={() => setShowForm(true)}>sign up form</button>.
        </Paragraph>
      </InfoBox>
    </>
  )
}

export default Levels
